import "./index.css";
import 'flowbite';
import 'instantsearch.css/themes/satellite.css';
import instantsearch from 'instantsearch.js';
import { configure, hits, searchBox } from 'instantsearch.js/es/widgets';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

const searchInstance = function(hitsContainer) {
  return instantsearch({
    indexName: "documents",
    searchClient: instantMeiliSearch(
      "https://meilisearch.wisderm.com",
      process.env.MEILI_SEARCH_API_KEY,
      { placeholderSearch: false },
    ).searchClient,
    searchFunction(helper) {
      if (helper.state.query.trim() === '') {
        document.querySelector(hitsContainer).style.display = 'none';
      } else {
        document.querySelector(hitsContainer).style.display = 'block';
      }
      helper.search();
    },
  });
};

const searchBoxWidget = function (container) {
  return searchBox({
    container,
    placeholder: 'Search for ingredients, products, supplements and studies.',
    autofocus: true,
    cssClasses: {
      input: [
        "p-2",
        "self-center",
        "text-lg",
        "border-2 border-lilac-300",
        "focus:ring-0 focus:border-lilac-500",
      ],
      submit: [
        "bg-lilac-800",
        "border-2 border-lilac-300",
        "h-10",
      ],
      submitIcon: ["bg-lilac-800"],
    },
  })
};

const hitsWidget = function(container) {
  return hits({
    container,
    templates: {
      item(hit, { html, components }) {
        return html`
          <a href="${hit.link}">
            <div class="cursor-pointer text-left text-gray-700 hover:text-lilac-800 hover:underline">
              ${components.Highlight({ attribute: 'name', hit })}
            </div>
          </a>
        `;
      },
      empty: '<div class="ais-Hits-item text-left text-gray-700">No results</div>',
    }
  })
};

const configureWidget = configure({ hitsPerPage: 5 });

const headerSearch = searchInstance("#headerHits");
headerSearch.addWidgets([
    searchBoxWidget("#headerSearchbox"),
    hitsWidget("#headerHits"),
    configureWidget,
]);
headerSearch.start();

if (document.querySelector('#searchbox')) {
  const search = searchInstance("#hits");
  search.addWidgets([
      searchBoxWidget("#searchbox"),
      hitsWidget("#hits"),
      configureWidget,
  ]);
  search.start();
}

const searchBtn = document.querySelector('#search-btn');
const searchDiv = document.querySelector('#search-div');
searchBtn.addEventListener('click', () => {
  searchDiv.classList.toggle('hidden');
  if (!searchDiv.classList.contains('hidden')) {
    document.querySelector('input.ais-SearchBox-input').focus();
  };
});


['ingredient', 'product', 'supplement', 'clinical', 'mechanistic', 'tolerability'].forEach(kind => {
  const kindToggle = document.querySelector(`#${kind}-toggle`);
  if (kindToggle !== null) {
    kindToggle.addEventListener('click', () => {
      const moreRows = document.querySelectorAll(`.more-${kind}`);
      moreRows.forEach(row => row.classList.toggle('hidden'));
      if (kindToggle.textContent.includes('more')) {
        kindToggle.textContent = kindToggle.textContent.replace('more', 'fewer');
      } else {
        kindToggle.textContent = kindToggle.textContent.replace('fewer', 'more');
      };
    });
  };
})